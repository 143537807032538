import { __assign, __awaiter, __generator } from "tslib";
import _ from 'lodash';
import createAsyncActions from '../../../../helpers/create-async-actions';
import { getChannelById } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isVODChannel } from '../../../../../utils/channel-helpers';
import { listChannelVideos } from '@wix/wix-vod-api/dist/src/public/channel-videos/channel-videos';
import { listYoutubeChannelVideos } from '@wix/wix-vod-api/dist/src/public/youtube/videos';
import { normalizeArrayOfVideos } from '../../../../helpers/normalized';
import { getPublic as getPublicChannel } from '../../../../../../api/public/channel/channel';
import { getHydratedData } from '../../../../../../widget/redux/client/hydrated-data/hydrated-data';
import { SortOrder } from '@wix/wix-vod-gql-api/dist/src/public/channel/types';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { VIDEO_SOURCES } from '@wix/wix-vod-constants/dist/common/video-sources';
import { publicApiV3 } from '../../../../../../api/v3/public';
var ACTIONS = createAsyncActions('SERVER.CHANNEL.VIDEOS.LIST');
export var NAMES = ACTIONS.NAMES;
var START = ACTIONS.START, SUCCESS = ACTIONS.SUCCESS, FAIL = ACTIONS.FAIL;
export var defaultParams = {
    sort: {
        order: 'custom',
        direction: 'asc',
    },
    paging: {
        size: 1000,
    },
};
export var listPublicChannelVideosGql = function (channelId, options) {
    if (options === void 0) { options = {}; }
    _.defaultsDeep(options, defaultParams);
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var params, state, hydratedChannelVideos, response, items, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = { id: channelId, options: options };
                    state = getState();
                    dispatch(START(params));
                    hydratedChannelVideos = getHydratedData(state).channelVideos;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (hydratedChannelVideos ||
                            publicApiV3.channel.getChannelVideos({
                                channelId: channelId,
                                videosCount: options.paging.size,
                                cursor: options.paging.cursor,
                                sort: SortOrder.CUSTOM_DESC,
                                query: options.query,
                            }))];
                case 2:
                    response = _a.sent();
                    items = response.items;
                    if (items.length && items[0].videoSource === VIDEO_SOURCES.YOUTUBE) {
                        items = items.map(function (item) { return (__assign(__assign({}, item), { id: item.externalId })); });
                    }
                    response = {
                        paging: { cursor: response.cursor, size: options.paging.size },
                        sort: SortOrder.CUSTOM_ASC,
                        data: normalizeArrayOfVideos(items),
                    };
                    dispatch(SUCCESS(params, response));
                    return [2 /*return*/, response];
                case 3:
                    error_1 = _a.sent();
                    dispatch(FAIL(params, error_1, {
                        analytics: { type: 'error', name: 'shared.channel.videos.list' },
                    }));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var listPublicChannelVideos = function (channelId, options) {
    if (options === void 0) { options = {}; }
    _.defaultsDeep(options, defaultParams);
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var state, params, _a, hydratedChannel, hydratedChannelVideos, templateMetaSiteId, response, channel, _b, _c, channelResponse, error_2;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    state = getState();
                    if (isGraphAPIEnabled()) {
                        return [2 /*return*/, dispatch(listPublicChannelVideosGql(channelId, options))];
                    }
                    params = { id: channelId, options: options };
                    _a = getHydratedData(state), hydratedChannel = _a.channel, hydratedChannelVideos = _a.channelVideos, templateMetaSiteId = _a.templateMetaSiteId;
                    dispatch(START(params));
                    if (!hydratedChannel) return [3 /*break*/, 2];
                    return [4 /*yield*/, getPublicChannel(channelId, { hydratedChannel: hydratedChannel })];
                case 1:
                    _b = (_d.sent()).data;
                    return [3 /*break*/, 3];
                case 2:
                    _b = getChannelById(state, channelId);
                    _d.label = 3;
                case 3:
                    channel = _b;
                    _d.label = 4;
                case 4:
                    _d.trys.push([4, 14, , 15]);
                    if (!channel) return [3 /*break*/, 9];
                    if (!isVODChannel(channel)) return [3 /*break*/, 6];
                    return [4 /*yield*/, listChannelVideos(__assign(__assign({}, options), { channelId: channel.id, hydratedChannelVideos: hydratedChannelVideos,
                            templateMetaSiteId: templateMetaSiteId }))];
                case 5:
                    _c = _d.sent();
                    return [3 /*break*/, 8];
                case 6: return [4 /*yield*/, listYoutubeChannelVideos(channel, options, hydratedChannelVideos)];
                case 7:
                    _c = _d.sent();
                    _d.label = 8;
                case 8:
                    response = _c;
                    return [3 /*break*/, 13];
                case 9: return [4 /*yield*/, listChannelVideos(__assign(__assign({}, options), { channelId: channelId,
                        templateMetaSiteId: templateMetaSiteId }))];
                case 10:
                    response = _d.sent();
                    if (!!response.data.length) return [3 /*break*/, 13];
                    return [4 /*yield*/, getPublicChannel(channelId, {
                            hydratedChannel: hydratedChannel,
                        })];
                case 11:
                    channelResponse = _d.sent();
                    if (!channelResponse.data.externalId) return [3 /*break*/, 13];
                    return [4 /*yield*/, listYoutubeChannelVideos(channelResponse.data, options, hydratedChannelVideos)];
                case 12:
                    response = _d.sent();
                    _d.label = 13;
                case 13:
                    response = {
                        paging: response.paging,
                        sort: response.sort,
                        data: normalizeArrayOfVideos(response.data),
                    };
                    dispatch(SUCCESS(params, response));
                    return [2 /*return*/, response];
                case 14:
                    error_2 = _d.sent();
                    dispatch(FAIL(params, error_2, {
                        analytics: { type: 'error', name: 'shared.channel.videos.list' },
                    }));
                    return [3 /*break*/, 15];
                case 15: return [2 /*return*/];
            }
        });
    }); };
};
